.navbar {
  background:radial-gradient(circle at 50% 50%, rgba(55, 19, 133, 0.76) 75%, rgba(217, 202, 203, 1) 97%, rgba(255, 255, 255, 0.96) 100%);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  margin: 10px;
  border-radius: 10px 50px;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  max-width: 1500px;
  border-radius: 10px 50px;
}

.nav-logo {
  color: #f5b921;
  align-items: left;
  margin-left: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2rem;
  text-align: left;
  padding-right: 0;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 1.2rem;
}

.nav-links {
  color: #fff;
  padding: 0.4rem 0.8rem;
  height: 100%;
  border-bottom: 1.5px solid transparent;
}

.fa-code {
  margin-left: 0.8rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 0.8rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 1.5px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 0, 1) 38%, rgba(238, 130, 238, 1) 100%);
}

.nav-item .active {
  color: #dcff40;
  border: 1px solid #dcff40;
}

.nav-icon {
  display: none;
 
}

@media screen and (max-width: 950px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 65px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    border-radius: 10px 50px;
  }

  .nav-menu.active {
    background: rgba(55, 19, 133, 0.76);
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #dcff40;
    border: none;
  }
  .nav-links {
    padding: 1.2rem;
    width: 100%;
    display: table;
    text-transform: none;
    color: #fff;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: -10%;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.2rem;
    cursor: pointer;
    color: #dcff40;
  }
}