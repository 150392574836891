.head-bar img{

    display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;

}
@media only screen and (max-width: 800px) {

  .head-bar img{
          width: 100%;
          height: 100%;
          background-size: 100% auto !important;
  }
}